<footer class="l-footer">
    <div class="u-block">
      <div class="p-footer-top">
        <ul class="p-footer-link">
          <li><a href={{noteOnUseUrl}} target="_blank">{{'footer.links.link1' | translate}}</a></li>
          <li><a href={{termOfUseUrl}} target="_blank">{{'footer.links.link2' | translate}}</a></li>
          <li><a href="http://www.manulife.co.jp/privacypolicy" target="_blank">{{'footer.links.link3' | translate}}</a></li>
          <li><a href="http://www.manulife.co.jp/shop" target="_blank">{{'footer.links.link4' | translate}}</a></li>
          <li><a href="https://support.manulife.co.jp/category?site_domain=mypage" target="_blank">{{'footer.links.link5' | translate}}</a></li>
        </ul>
        <ul class="p-footer-sns">
          <!-- <li><a href="https://www.facebook.com/Life2.0.ManulifeJapan" target="_blank"><img src="/assets/images/footer_im01.svg" alt="Facebook"></a></li> -->
          <li><a href="https://www.youtube.com/user/ManulifeJapan" target="_blank"><img src="/assets/images/footer_im02.svg" alt="Youtube"></a></li>
        </ul>
      </div>
      <div class="p-footer-bottom">
        <p class="p-footer-copyright"><small>©2016 Manulife Life Insurance Company.</small></p>
      </div>
    </div>
  <!-- /l-footer --></footer>