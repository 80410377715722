import { NgModule } from '@angular/core';
import { AuthModule } from 'angular-auth-oidc-client';
import { environment } from '../../environments/environment';


@NgModule({
  imports: [AuthModule.forRoot({
    config: {
          authority: environment.authority,
          redirectUrl: environment.redirectUrl,
          authWellknownEndpointUrl: environment.authWellknownEndpointUrl,
          postLogoutRedirectUri: window.location.origin,
          clientId: environment.clientId,
          scope: environment.scope,
          responseType: environment.responseType,
          silentRenew: true,
          useRefreshToken: true,
          renewTimeBeforeTokenExpiresInSeconds: 30
      }
  })],
exports: [AuthModule],
})
export class AuthConfigModule { }
