import { Component } from '@angular/core';
import { common_urls } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  noteOnUseUrl: string = `${common_urls.cwsBaseUrl}/resource/1668755720000/MLJ_CWS_LoginStaticResource/Attachment/Notesonuse.pdf`;
  termOfUseUrl: string = `${common_urls.cwsBaseUrl}/resource/1668755720000/MLJ_CWS_LoginStaticResource/Attachment/ManulifeMypageTermsofUse.pdf`;

}
