<mat-dialog-content>

    <div class="modal__modal-content__modal-body modal-body">
        <ng-container *ngTemplateOutlet="data.customBody"></ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <div class="actions-container">
        <button mat-flat-button color="accent" (click)="cancel()">
            {{'gasa.confirmation.close' | translate}}
        </button>
    </div>

</mat-dialog-actions>