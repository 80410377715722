import { common_urls } from 'src/environments/environment';

export class CwsUrlNavigator {

  static id: string;
  static policyNumber: string;
  static sProdCd: string;
  static lProdCd: string | null;
  static authUser: boolean = false;

  private static get BASE_URL(): string {
    return common_urls.cwsBaseUrl;
  }

  static CWS_Navigation_URL(navigatingPage: string, isPolicyInquiry: boolean = false, isId: boolean = false, isUserId: boolean = false, isCws: boolean = false, isAuthUser:boolean = false): string {
    this.authUser = isAuthUser;
    return `${this.BASE_URL}${this.isAuthenticUser}/${navigatingPage}` + this.GENERATE_QUERY_PARAM(isPolicyInquiry, isId, isUserId, isCws);
  }

  static get isAuthenticUser() {
    return this.authUser ? "/apex" : "";
  }

  static GENERATE_QUERY_PARAM(isPolicyInquiry: boolean = false, isId: boolean = false, isUserId: boolean = false, isCws: boolean = false) {
    let queryParams = "";
    queryParams = isPolicyInquiry ? this.QUERY_PARAMS_POLICY_INQUIRY : "";
    if (isId) {
      queryParams += queryParams ? "&" : "";
      queryParams += this.QUERY_PARAMS_ID(this.id);
    }

    if (isUserId) {
      queryParams += queryParams ? "&" : "";
      queryParams += this.QUERY_PARAMS_USER_ID;
    }

    if (isCws) {
      queryParams += queryParams ? "&" : "";
      queryParams += this.QUERY_PARAMS_CWS;
    }

    return queryParams ? `?${queryParams}` : "";
  }

  static get QUERY_PARAMS_POLICY_INQUIRY(): string {
    return `from=policyinquiry`;
  }

  static QUERY_PARAMS_ID(id: string): string {
    return `id=${id}`;
  }

  static get QUERY_PARAMS_USER_ID(): string {
    return `userId=`;
  }

  static get QUERY_PARAMS_CWS(): string {
    return `cws=1`;
  }
}
