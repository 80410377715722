<div class="modal" id="modal-survey">
    <div class="modal-content">
      <div class="modal-header">
        <h2>{{'survey-logout-modal.header' | translate}}</h2>
        <span class="close" (click)="hideModal()">&times;</span>
      </div>
      <div class="modal-body-footer-spacing">
        <div class="modal-body">
            <p>{{'survey-logout-modal.body.text1' | translate}}</p>
            <p>{{'survey-logout-modal.body.text2' | translate}}</p>
            <p>{{'survey-logout-modal.body.text3' | translate}}</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn1" mat-flat-button (click)="navigateToSurvey()">
                <span class="btn-text-adjust">
                  <span>{{'survey-logout-modal.blueColorBtn.text1' | translate}}</span>
                  <span>{{'survey-logout-modal.blueColorBtn.text2' | translate}}</span>
                </span>
            </button>
            <button class="btn btn2" mat-flat-button (click)="navigateToLogout()">
                <span class="btn-text-adjust">
                  <span>{{'survey-logout-modal.redColorBtn.text1' | translate}}</span>
                  <span>{{'survey-logout-modal.redColorBtn.text2' | translate}}</span>
                </span>
            </button>
            <button class="btn btn3" mat-flat-button (click)="hideModal()">
                <span class="btn-text-adjust">
                  <span>{{'survey-logout-modal.yellowColorBtn.text1' | translate}}</span>
                  <span>{{'survey-logout-modal.yellowColorBtn.text2' | translate}}</span>
                </span>
            </button>
          </div>
      </div>
    </div>
  </div>
  