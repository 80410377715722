import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, switchMap } from 'rxjs';
import { SessionData } from '../gasa-fund-enhancement/shared/constants';
import { SessionService } from '../gasa-fund-enhancement/services/session.service';
import { take } from 'rxjs';
import { stop_mailing_certificates_url } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  isAuthenticated!: boolean;
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private httpClient: HttpClient,
    private sessionservice: SessionService
  ) {}

  /* Logout user from OIDC */
  OIDClogout(url: string) {
    this.sessionservice.removeItem(SessionData.ToBeFund);    // changes for GFE-1634
    console.log('Entered Logout');

    this.verifyAuthentication();
    this.verifyToken();
    this.logoutUser(url);
  }

  /* function to check OIDC Authentication */
  verifyAuthentication(): void {
    this.oidcSecurityService.isAuthenticated$.subscribe(
      ({ isAuthenticated }) => {
        this.isAuthenticated = isAuthenticated;

        console.warn('authenticated: ', isAuthenticated);
      }
    );
  }

  /* function to check token */
  verifyToken(): void {
    this.oidcSecurityService.getIdToken().subscribe((idToken: string) => {
      console.log('id token', idToken);
    });
  }

  /* function to logout user from OIDC */
  logoutUser(url: string): void {
    this.oidcSecurityService.logoff().subscribe((result) => {
        console.log('logoff result', result);
        this.redirectToCWS(url);
    })
  }

  /* function to redirect user to cws page after logout */
  redirectToCWS(url: string): void {
    // window.location.href = url;
    window.open(url, '_self');
  }

  // /* CWS call on survey link open */
  // cwsSurveyCall(url: string, obj: { userid: string }): Observable<any> {
  //   const cwsUrl: string = `${url}/services/apexrest/user/`;
  //   return this.oidcSecurityService.getAccessToken().pipe(
  //     switchMap((accessToken) => {
  //       const requestOptions = {
  //         headers: new HttpHeaders({
  //           Authorization: 'Bearer ' + accessToken,
  //         }),
  //       };

  //       return this.httpClient.post(cwsUrl, obj, requestOptions);
  //     })
  //   );
  // }

  // /* Function to logoff users locally from OIDC */
  // logoffLocal() {
  //   console.log('local logoff called');
  //   this.oidcSecurityService.logoffLocal();
  // }

  // Logout user from OIDC, clear session storage and redirect user to CWS error page url
  navigateToCWSErrorPage(): void {
    this.oidcSecurityService.logoffAndRevokeTokens().pipe(take(1)).subscribe(res => console.log(res));
    sessionStorage.clear();
    window.open(`${stop_mailing_certificates_url.cwsBaseUrl}${stop_mailing_certificates_url.errorPage}`, '_self');
  }
}
