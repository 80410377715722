import { Component } from '@angular/core';

@Component({
  selector: 'app-cws-footer',
  templateUrl: './cws-footer.component.html',
  styleUrls: ['./cws-footer.component.scss']
})
export class CwsFooterComponent {

}
