import { Injectable } from '@angular/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader, TranslateModuleConfig } from '@ngx-translate/core';
import { forkJoin, map, Observable } from 'rxjs';
@Injectable({
    providedIn: 'root',
})

export class LogMissingTranslation implements MissingTranslationHandler {
    public handle(params: MissingTranslationHandlerParams) {
        console.log(`[MISSING] ${params.key}`);
        return params.key;
    }
}

type ProjectImport = (lang: string) => any;

type TranslationObject = {
    [key: string]: string | TranslationObject;
};

/**
 * Small utility for cloning translation objects. Not for use in other circumstances
 * @param weak The lowest priority object
 * @param strong The highest priority object
 */
function deepClone(weak: TranslationObject, strong: TranslationObject): TranslationObject {
    // weak properties are overwritten by strong
    const strongClone = Object.keys(weak).reduce((acc, cur) => {
        const strongValue = strong[cur];

        // if strong doesn't contain property, there's nothing to do
        if (strongValue === undefined) {
            return acc;
        }

        if (typeof strongValue === 'string') {
            // if the type of strongValue is string, replace weak
            acc[cur] = strongValue;
        } else if (typeof strongValue === 'object') {
            // if the type is object, we need to replace or recursively clone
            const weakValue = acc[cur];
            // if a weak value doesn't exist, or is not an object,
            // just assign the strong value
            if (!weakValue || typeof weakValue !== 'object') {
                acc[cur] = strongValue;
            } else {
                // otherwise, do a deep clone of both objects
                acc[cur] = deepClone(weakValue, strongValue);
            }
        }

        return acc;
    }, weak);

    return {
        // spread strong in here to apply values not in weak
        ...strong,

        // add the overwritten deep cloned of weak -> strong
        ...strongClone,
    };
}

/**
 * Loads translation JSON assets via webpack
 */
class WebpackTranslateLoader implements TranslateLoader {

    constructor(private projectImport: ProjectImport[]) { }

    public getTranslation(lang: string) {
        return forkJoin([
            // imports the common translation file from Gaia's asset folder
            //import(`@gaia/lib/assets/i18n/${lang}.json`),

            // if provided to the constructor, will be an additional object-like to
            // merge with the common translations
            
            ...(this.projectImport.map(imp => imp(lang)) as Array<Observable<any>>),
        ]).pipe(
            map((files)=> { return files.reduce((acc: any, cur: any) => deepClone(acc, cur), {})}),
        );
    }
}


export enum AvailableLanguages {
    English = 'en',
    Japanese = 'ja',
}

export const DEFAULT_LANGUAGE = AvailableLanguages.Japanese;

export const translateConfig: TranslateModuleConfig = {
    loader: {
        provide: TranslateLoader,
        useFactory: () => new WebpackTranslateLoader([
            // add imports in order of importance.
            // the result will be a merge of all files
            // for a single language, with the earlier
            // files being overwritten by later files if
            // they have overlapping properties

            lang => import(`../assets/i18n/${lang}.json`),
            // lang => import(`./stp-cws-registration/assets/i18n/${lang}.json`),
            lang => import(`./gasa-fund-enhancement/assets/i18n/${lang}.json`),
            lang => import(`./ereport/assets/i18n/${lang}.json`),
            lang => import(`./registration/assets/i18n/${lang}.json`),
            lang => import(`./stop-mailing-certificates/assets/i18n/${lang}.json`)
        ])
    },
    defaultLanguage: DEFAULT_LANGUAGE,
    extend: true,
    missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LogMissingTranslation,
    },
};
