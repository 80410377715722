<div class="modal" id="modal-logout">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h2>{{'logout-modal.body.header' | translate}}</h2> -->
        <span class="close" (click)="hideModal()">&times;</span>
      </div>
      <div class="modal-body-footer-spacing">
        <div class="modal-body">
          {{'logout-modal.body' | translate}}
          </div>
          <div class="modal-footer">
            <button class="btn btn2" mat-flat-button (click)="navigateToLogout()">
                <span>{{'logout-modal.redColorBtn' | translate}}</span><br>
            </button>
            <button class="btn btn3" mat-flat-button (click)="hideModal()">
                <span>{{'logout-modal.yellowColorBtn' | translate}}</span><br>
            </button>
          </div>
      </div>
    </div>
  </div>
  