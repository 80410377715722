export enum SideMenuItems {
  ChangeContractInformation = "changeContractInformationItem",
  Cancellation = "cancellationItem",
  ReissueProcedure = "reissueProcedureItem",
  ProceduresInsurancePremiumPayment = "proceduresInsurancePremiumPaymentItem",
  LoansFundWithdrawals = "loansFundWithdrawalsItem"
}

// Change Contract Information child menu
export enum ChangeContractInformation {
  ChangeNamePolicyHolderInsuredInvoiceOrder = "changeNamePolicyholderInsuredInvoiceOrderSubItem",
  ChangeNameBeneficiaryDesignatedProxyClaimantContractorInvoiceOrder = "changeNameBeneficiaryDesignatedProxyClaimantContractorInvoiceOrderSubItem",
  ChangeAddressPhoneNumber = "changeAddressPhoneNumberSubItem"
}

// Reissue Child Menu
export enum ReissueProcedure {
  TaxCertificate = "issuanceLifeInsurancePremiumDeductionCertificateSubItem",
  InsurancePolicyReissue = "insurancePolicyReissueSubItem"
}

export enum Cancellation{
  Cancellation = "cancellationSubItem",
  CancellationInvoiceRequest = "cancellationInvoiceRequestSubItem",
  CancellationDynamic = "cancellationDynamicSubItem",
}

export enum ProceduresInsurancePremiumPayment{
  ChangeTransferAccount = "changeTransferAccountSubItem",
  CreditCardChange = "creditCardChangeSubItem"
}

export enum LoansFundWithdrawals{
  UseOfPolicyholderLoans = "useOfPolicyholderLoansSubItem"
}