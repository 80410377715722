export const environment = {
    production: false,
    authority: 'www.test.salesforce.com',
    // redirectUrl: 'https://svcmfcjpncwsdjpe01.z11.web.core.windows.net',
    redirectUrl: 'https://dev.selfservice.mypage.manulife.co.jp',
    authWellknownEndpointUrl: 'https://mlj--dev31.sandbox.my.site.com/cws',
    clientId: '3MVG9e2mBbZnmM6nBhbuPFy88qff0zAeiKLaGmWLpRd7kExSk9OpbV7ly28Zdz2puZNI4Iv4lEQ8I3WZPOGLt',
    scope: 'openid api',
    responseType: 'code',
};

export const common_urls = {
    bffBaseUrl: 'https://jp-dev-api.ap.manulife.com/ext/cws/bff/api/v1',
    cwsBaseUrl: 'https://mlj--dev31.sandbox.my.site.com/cws',
    surveyUrl: '', // not in use
    footerNotesUrl: 'https://mlj--dev31.sandbox.my.site.com/cws/resource/1692608748000',
    footerTermsOfUseUrl: 'https://mlj--dev31.sandbox.my.site.com/cws/resource/1722957122000',
    oodMenuItemsUrl: "https://jp-dev-api.ap.manulife.com/ext/dev3/cws/bff/api/v1",
    adobeAnalyticsUrl: 'https://assets.adobedtm.com/b84dbf01908c/ee406859b983/launch-3ba032760f58-development.min.js'
}

export const common_url_sur = {
    bffBaseSurUrl:'https://dev.apps.manulife.co.jp/ext/cws-sur2/bff/api/v1/',
    cwsLoginUrl:'https://mlj--dev31.sandbox.my.site.com/cws/auth',
    publicKey: `gbBkW3Ap+XLKIdV+csMHfApHeOk+I2r+wdWKj7kNdtM=`
}

export const common_urls_gasa = {
    bffBaseUrl:'https://jp-dev-api.ap.manulife.com/ext/dev3/cws/bff/api/v1/gasa',
    cwsBaseUrl:'https://mlj--dev31.sandbox.my.site.com/cws',
}

export const ereport_urls = {
    bffBaseEreportUrl: 'https://jp-dev-api.ap.manulife.com/ext/dev3-fraereport/cws/bff/api/v1'
}

 export const common_urls_c360 = {
    bffBaseUrl:'https://dev.apps.manulife.co.jp/ext/cws3-c360/bff/api/v1'
}

 
export const stop_mailing_certificates_url = {
    bffBaseUrl: 'https://jpw-dev-api.ap.manulife.com/ext/cws/bff/api/v1',
    cwsBaseUrl: 'https://mlj--dev31.sandbox.my.site.com/cws',
    errorPage: '/error_page'
}
