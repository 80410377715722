import { DatePipe, DecimalPipe } from "@angular/common";

import { CURRENCIES, SessionData, DATE_LABELS } from "./constants"
import { FUND_TARGET_VALUE } from "./constants"
import { POLICY_SEARCH_DATA } from "./models/policy-search-model"

/* function to swap the target fund value at 0th index */
export function fundDataMapped(policy: POLICY_SEARCH_DATA) {
    try {
        policy.funds.map((fund, index) => {
            if (fund.code != FUND_TARGET_VALUE.code && Number(fund.specialAccountRatio) && Number(fund.specialAccountRatio) == FUND_TARGET_VALUE.ratio) {
                [policy.funds[0], policy.funds[index]] = [policy.funds[index], policy.funds[0]]
            }
        })
    }
    catch { console.error("Error found in funds data") }
}

/* function to set the policyStatus on the basis of different conditions */
export function policyStatusMapped(policy: POLICY_SEARCH_DATA) {
    try {
        let result = '';
        if (policy.productFamilyCodeStandard1 > 500) {
            if (policy.mailCode == 'Y')
              result = '10'
            else if (policy.policyStatus == 'A') {
              if (policy.phaseIndicator == '0')
                result = '1'
              else if (policy.phaseIndicator == '1' || policy.phaseIndicator == '2') {
                if (policy.defferTerm == "0" && policy.recieptDt === null)
                  result = '5'
                else
                  result = '6'
              }
              else
                result = '8'
            }
            else
              result = '8'
          }
        policy.policyStatus = result;
    }
    catch { console.error("Error found in policyStatus") }
}

/* Function to get the consolidated date value */
export function concludedDateValue(baseDate: string, format?: string): string {
    if (baseDate) {
        let mappedDate = "";
        if (format == "date") {
            let date = new Date(baseDate);
            let datePipe = new DatePipe('en-US');
            
            mappedDate = datePipe.transform(date, 'yyyy') + DATE_LABELS['year'];
            mappedDate += datePipe.transform(date, 'MM') + DATE_LABELS['month'];
            mappedDate += datePipe.transform(date, 'dd') + DATE_LABELS['day'];
        }
        else {
            let year = baseDate.split("-")[0];
            let month = baseDate.split("-")[1];
            let day = baseDate.split("-")[2];

            mappedDate = year + DATE_LABELS['year'];
            mappedDate += ((month && month.length < 2) ? `0${month}` : month) + DATE_LABELS['month'];
            mappedDate += ((day && day.length < 2) ? `0${day}` : day) + DATE_LABELS['day'];
        }
        // returns the consolidated date with labels as year, month and day
        return mappedDate;
    }
    return "";
}

/* Function to get the concated amount and currency value */
export function concatAmountCurrency(amount: string, currency: string, isDecimal?: boolean, divisor = 100): string {
    let finalValue = "";
    const numberPipe = new DecimalPipe('en-US')
    if (isDecimal)
        finalValue = numberPipe.transform((Number(amount) / divisor), '1.2-2') || "";
    else
        finalValue = numberPipe.transform(amount) || "";
    finalValue += " ";
    finalValue += (currency in CURRENCIES) ? CURRENCIES[currency] : "";
    // returns the concated value for ammount with currency symbol and with upto two decimal places incase of foreign currency
    return finalValue;
}

/**
 * @param amount this method converts a number to comma seperated format
 * @returns the formatted value in comma seperated format
 */
export function convertToCommaSeperatedFormat(amount: string, isDecimal?: boolean, divisor = 100) {
    const numberPipe = new DecimalPipe('en-US')
    if (isDecimal)
        return numberPipe.transform((Number(amount) / divisor), '1.2-2') || "";
    return numberPipe.transform(amount) || "";
}
