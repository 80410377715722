import { Injectable, TemplateRef } from '@angular/core';
import { Cancellation, ChangeContractInformation, LoansFundWithdrawals, ProceduresInsurancePremiumPayment, ReissueProcedure } from './enums/side-menu-items.enums';
import { ModalConfig, SideNavConfigItem } from '@cws-ui/ngx-cws-app-layout';
import { CwsUrlNavigator } from '../cws-url-navigator';
import { CancellationErrorCodes, LoansFundWithdrawalsErrorCodes, ProcedurePremiumInsuranceErrorCodes, ReissueErrorCodes } from './enums/side-menu-error-codes.enum';
import { SFDCNavigationEventUrlMapping } from 'src/app/shared/side-menu-popup/side-menu-constants';

@Injectable({
  providedIn: 'root'
})
export class SideMenuPopupService {

  constructor() { }

  /**
  * Following method is related to show popup or navigate as per provided  business logic for all sub-menus of contract information changes and should not be removed.
  * @param sideMenuItem : when we click on one of the menu items in the side navigation bar, that menu item  is represented by sideMenuItem parameter here.
  * @param subMenu : This param hold all the information and properties for the clicked submenu item.
  * @param errorFaqModal : This is a template ref for show error FAQ modal, contains HTML to show in pop-up
  * @param showFamilyNameMsg : This is a template ref for Change Name Policy Holder Insured Invoice Order, contains HTML to show in pop-up
  * @param addressChange : This is a template ref for Address change, contains HTML to show in pop-up
  * @returns : [ModalConfig | null, string | null]
  */
  changeContractInformation(sideMenuItem: string, subMenu: SideNavConfigItem, errorFaqModal: TemplateRef<any>, showFamilyNameMsg: TemplateRef<any>, addressChange: TemplateRef<any>): [ModalConfig | null, string | null] {
    // this modal config defines the pop-up configuration , it's common for change name policyholder and address change.
    const modalConfig: ModalConfig = {
      header: "",
      buttons: [
        {
          text: 'app.common-modal.yes-button',
          result: "true",
          classes: 'btn btn2'
        },
        {
          text: 'app.common-modal.no-button',
          result: "false",
          classes: 'btn btn3'
        }
      ],
      translate: true
    };
    // As per clicked submenu, respected function get called.
    switch (sideMenuItem) {
      case ChangeContractInformation.ChangeNamePolicyHolderInsuredInvoiceOrder:
        return this.setChangeNamePolicyHolderConfigModal(sideMenuItem, subMenu, modalConfig, showFamilyNameMsg);
      case ChangeContractInformation.ChangeNameBeneficiaryDesignatedProxyClaimantContractorInvoiceOrder:
        return this.setChangeNameBeneficiaryConfigModal(sideMenuItem, subMenu, errorFaqModal);
      default:
        return this.setAddressConfigModal(sideMenuItem, subMenu, modalConfig, addressChange);
    }
  }

  /**
* Following method is related to show popup or navigate as per provided  business logic for Change Name Policy Holder sub-menu and should not be removed.
* @param sideMenuItem : when we click on one of the menu items in the side navigation bar, that menu item  is represented by sideMenuItem parameter here.
* @param subMenu : This param hold all the information and properties for the clicked submenu item.
* @param modalConfig : Modal config for pop-up, in case of common , passed from parent function.
* @param showFamilyNameMsg : This is a template ref for Change Name Policy Holder Insured Invoice Order, contains HTML to show in pop-up
* @returns : [ModalConfig | null, string | null]
*/
  setChangeNamePolicyHolderConfigModal(sideMenuItem: string, subMenu: SideNavConfigItem, modalConfig: ModalConfig, showFamilyNameMsg: TemplateRef<any>): [ModalConfig | null, string | null] {
    if (subMenu.metadata && Object.keys(subMenu.metadata).length) {
      modalConfig.header = 'app.common-modal.contract-information-change.header';
      modalConfig.customBody = showFamilyNameMsg;
      let redirectUrl;
      if (subMenu.metadata["callCenterUser"]) {
        redirectUrl = CwsUrlNavigator.CWS_Navigation_URL(SFDCNavigationEventUrlMapping[sideMenuItem], true, true, true, true, true);
      }
      else {
        redirectUrl = CwsUrlNavigator.CWS_Navigation_URL(SFDCNavigationEventUrlMapping[sideMenuItem], true, true);
      }
      // we are returning both , config and redirection. In this case , redirection url : on pop-up we need to perform navigation on action button .
      return [modalConfig, redirectUrl];
    }
    // if no condition met, then returning null, it means no action is required.
    return [null, null];
  }

  /**
 * Following method is related to show popup or navigate as per provided  business logic for Change Name Beneficiary sub-menu and should not be removed.
 * @param sideMenuItem : when we click on one of the menu items in the side navigation bar, that menu item  is represented by sideMenuItem parameter here.
 * @param subMenu : This param hold all the information and properties for the clicked submenu item.
 * @param changeNameBody : This is a template ref for error Faq Modal, contains HTML to show in pop-up
 * @returns : [ModalConfig | null, string | null]
 */
  setChangeNameBeneficiaryConfigModal(sideMenuItem: string, subMenu: SideNavConfigItem, errorFaqModal: TemplateRef<any>): [ModalConfig | null, string | null] {
    if (subMenu.metadata && Object.keys(subMenu.metadata).length) {
      if (subMenu.metadata["displayRightHolderMsg"]) {
        let redirectUrl = this.emailEnquiryNavigation(subMenu);
        // we are returning both , config and redirection. In this case , redirection url : on pop-up we need to perform navigation on action button .
        return [this.errorFaqModalConfig(errorFaqModal), redirectUrl];
      }
      else {
        if (subMenu.metadata["callCenterUser"]) {
          // in case of no pop-up and only navigation , returning null.
          return [null, CwsUrlNavigator.CWS_Navigation_URL(SFDCNavigationEventUrlMapping[sideMenuItem], true, true, true, true, true)];
        }
        else {
          // in case of no pop-up and only navigation , returning null.
          return [null, CwsUrlNavigator.CWS_Navigation_URL(SFDCNavigationEventUrlMapping[sideMenuItem], true, true)];
        }
      }
    }
    // if no condition met, then returning null, it means no action is required.
    return [null, null];
  }

  /**
  * Following method is related to show popup or navigate as per provided  business logic for address change sub-menu and should not be removed.
  * @param sideMenuItem : when we click on one of the menu items in the side navigation bar, that menu item  is represented by sideMenuItem parameter here.
  * @param subMenu : This param hold all the information and properties for the clicked submenu item.
  * @param modalConfig : Modal config for pop-up, in case of common , passed from parent function.
  * @param addressChange : This is a template ref for Address change, contains HTML to show in pop-up
  * @returns : [ModalConfig | null, string | null]
  */
  setAddressConfigModal(sideMenuItem: string, subMenu: SideNavConfigItem, modalConfig: ModalConfig, addressChange: TemplateRef<any>): [ModalConfig | null, string | null] {
    modalConfig.header = 'app.common-modal.address-change.header';
    modalConfig.customBody = addressChange;
    const redirectUrl = CwsUrlNavigator.CWS_Navigation_URL(SFDCNavigationEventUrlMapping[sideMenuItem], true, true);
    // we are returning both , config and redirection. In this case , redirection url : on pop-up we need to perform navigation on action button .
    return [modalConfig, redirectUrl];
  }

  /**
  * Following method is related to show popup or navigate as per provided  business logic for all sub-menus of cancellation and should not be removed.
  * @param subMenu : This param hold all the information and properties for the clicked submenu item.
  * @param errorFaqModal : This is a template ref for Show error FAQ Modal, contains HTML to show in pop-up
  * @param progressModal : This is a template ref for Progress Modal, contains HTML to show in pop-up
  * @returns : [ModalConfig | null, string | null]
  */
  cancellation(sideMenuItem: string, subMenu: SideNavConfigItem, errorFaqModal: TemplateRef<any>, progressModal: TemplateRef<any>, cancelContractChange: TemplateRef<any>): [ModalConfig | null, string | null] {
    // As per clicked submenu, respected function get called.
    switch (sideMenuItem) {
      case Cancellation.CancellationInvoiceRequest:
        return this.cancellationSubItem(subMenu, errorFaqModal, progressModal);
      case Cancellation.CancellationDynamic:
        if (!subMenu.metadata["cancelContractModal"]) {
          return this.cancellationSubItem(subMenu, errorFaqModal, progressModal);
        }
        else {
          return this.cancellationDynamic(subMenu, errorFaqModal, cancelContractChange);
        }
      default:
        return [null, null];
    }
  }

  /**
  * Following method is related to show popup or navigate as per provided  business logic for cancellation sub-menus & bill request and should not be removed.
  * @param subMenu : This param hold all the information and properties for the clicked submenu item.
  * @param errorFaqModal : This is a template ref for Show error FAQ Modal, contains HTML to show in pop-up
  * @param progressModal : This is a template ref for Progress Modal, contains HTML to show in pop-up
  * @returns : [ModalConfig | null, string | null]
  */
  cancellationSubItem(subMenu: SideNavConfigItem, errorFaqModal: TemplateRef<any>, progressModal: TemplateRef<any>): [ModalConfig | null, string | null] {
    if (subMenu.metadata && Object.keys(subMenu.metadata).length) {
      switch (subMenu.metadata["popupActionCode"]) {
        case CancellationErrorCodes.CWSTDSB006:
          // in case of no pop-up and only navigation , returning null.
          return [null, CwsUrlNavigator.CWS_Navigation_URL(SFDCNavigationEventUrlMapping["tDSurrender1"], true, true)];
        case CancellationErrorCodes.CWSRWS007:
          // in case of no pop-up and only navigation , returning null.
          return [null, CwsUrlNavigator.CWS_Navigation_URL(SFDCNavigationEventUrlMapping["wMSurrenderNotes"], false, true, true)];
        default:
          // Call common popup action , if not specfic business error code not exists.
          return this.commonPopUpActionItems(subMenu, errorFaqModal, progressModal);

      }
    }
    // if no condition met or metadata value is not provided, then returning null, it means no action is required.
    return [null, null];
  }

  /**
* Following method is related to show popup or navigate as per provided  business logic for cancellation dynamic and should not be removed.
* @param subMenu : This param hold all the information and properties for the clicked submenu item.
* @param errorFaqModal : This is a template ref for Show error FAQ Modal, contains HTML to show in pop-up
* @param cancelContractChange : This is a template ref for cancel Contract Change, contains HTML to show in pop-up
* @returns : [ModalConfig | null, string | null]
*/
  cancellationDynamic(subMenu: SideNavConfigItem, errorFaqModal: TemplateRef<any>, cancelContractChange: TemplateRef<any>): [ModalConfig | null, string | null] {
    if (subMenu.metadata && Object.keys(subMenu.metadata).length) {
      if (subMenu.metadata["popupActionCode"] == CancellationErrorCodes.CWSRWS001
        || subMenu.metadata["popupActionCode"] == CancellationErrorCodes.CWSRWS002) {
        let redirectUrl = this.emailEnquiryNavigation(subMenu)
        // we are returning both , config and redirection. In this case , redirection url : on pop-up we need to perform navigation on action button .
        return [this.errorFaqModalConfig(errorFaqModal), redirectUrl];
      }
      else {
        const popUpConfig = {
          header: 'app.common-modal.cancellation.contract-change.header',
          buttons: [
            {
              text: 'app.common-modal.cancellation.contract-change.button-1',
              result: "cancelContractChange",
              classes: 'btn btn2'
            },
            {
              text: 'app.common-modal.cancel-button',
              result: "false",
              classes: 'btn btn2'
            }
          ],
          customBody: cancelContractChange,
          translate: true
        };
        return [popUpConfig, null];
      }
    }
    // if no condition met or metadata value is not provided, then returning null, it means no action is required.
    return [null, null];
  }
  /**
   * Following method is related to show popup or navigate as per provided  business logic for all sub-menus of reissue procedure and should not be removed.
   * @param sideMenuItem : when we click on one of the menu items in the side navigation bar, that menu item  is represented by sideMenuItem parameter here.
   * @param subMenu : This param hold all the information and properties for the clicked submenu item.
   * @param errorFaqModal : This is a template ref for show error faq modal, contains HTML to show in pop-up
   * @param progressModal : This is a template ref for show error faq modal, contains HTML to show in pop-up
   * @param taxCertificate : This is a template ref for Tax certificate, contains HTML to show in pop-up
   * @returns : [ModalConfig | null, string | null]
   */
  reissueProcedure(sideMenuItem: string, subMenu: SideNavConfigItem, errorFaqModal: TemplateRef<any>, progressModal: TemplateRef<any>, taxCertificate: any)
    : [ModalConfig | null, string | null, Boolean | null, { [key: string]: string } | null] {
    if (subMenu.metadata && Object.keys(subMenu.metadata).length) {
      // As per clicked submenu, respected function get called.
      switch (sideMenuItem) {
        case ReissueProcedure.InsurancePolicyReissue:
          return this.setInsurancePolicyReissueModalConfig(subMenu, errorFaqModal, progressModal);
        default:
          return this.setTaxCertificateModalConfig(sideMenuItem, subMenu, taxCertificate);
      }
    }
    // if no condition met or metadata value is not provided, then returning null, it means no action is required.
    return [null, null, null, null];
  }

  /**
 * Following method is related to show popup or navigate as per provided  business logic for Insurance Policy Reissue sub-menu and should not be removed.
 * @param subMenu : This param hold all the information and properties for the clicked submenu item.
 * @param errorFaqModal : This is a template ref for show error faq modal, contains HTML to show in pop-up
 * @param progressModal : This is a template ref for show error faq modal, contains HTML to show in pop-up
 * @returns : [ModalConfig | null, string | null]
 */
  setInsurancePolicyReissueModalConfig(subMenu: SideNavConfigItem, errorFaqModal: TemplateRef<any>, progressModal: TemplateRef<any>)
    : [ModalConfig | null, string | null, Boolean | null, { [key: string]: string } | null] {

    //popupActionCode : As per business , this property will return values and we need to show popup or navigate to url.
    if (subMenu.metadata["popupActionCode"] == ReissueErrorCodes.CWSREI007) {
      // in case of no pop-up and only navigation , returning null.
      return [null, CwsUrlNavigator.CWS_Navigation_URL(SFDCNavigationEventUrlMapping["policyReissue"], true, true), null, null];
    }
    else
      // Call common popup action , if not specfic business error code not exists.
      return [... this.commonPopUpActionItems(subMenu, errorFaqModal, progressModal), null, null];
  }

  /**
 * Following method is related to show popup or navigate as per provided  business logic for Tax certificate sub-menu and should not be removed.
 * @param sideMenuItem : when we click on one of the menu items in the side navigation bar, that menu item  is represented by sideMenuItem parameter here.
 * @param subMenu : This param hold all the information and properties for the clicked submenu item.
 * @param taxCertificate : This is a template ref for tax certificate, contains HTML to show in pop-up
 * @returns : [ModalConfig | null, string | null]
 */
  setTaxCertificateModalConfig(sideMenuItem: string, subMenu: SideNavConfigItem, taxCertificate: any)
    : [ModalConfig | null, string | null, Boolean | null, { [key: string]: string } | null] {
    if (subMenu.metadata && Object.keys(subMenu.metadata).length) {
      if (subMenu.metadata["taxReissueButtonVisible"]) {
        let cwsUrl = CwsUrlNavigator.CWS_Navigation_URL(SFDCNavigationEventUrlMapping[sideMenuItem], true, true);
        if (subMenu.metadata["callCenterUser"]) {
          // in case of no pop-up and only navigation , returning null.
          let etaxUrl = `etax/home?cwsBackUrl=${encodeURIComponent(cwsUrl)}&procUrl=${encodeURIComponent(location.href)}`;

          return [null, etaxUrl, true, null];
        }
        else {
          // in case of no pop-up and only navigation , returning null.
          return [null, cwsUrl, null, null];
        }
      }
      else {
        const config: ModalConfig = {
          header: "app.common-modal.taxCertificateIssue.header",
          buttons: [
            {
              text: 'info-modal.button-text',
              result: "false",
              classes: 'btn btn2'
            }
          ],
          customBody: taxCertificate,
          translate: true
        }
        // in case of pop-up with no action, returning null for redirection url.
        return [config, null, null, null];
      }
    }
    // if no condition met or metadata value is not provided, then returning null, it means no action is required.
    return [null, null, null, null];
  }

  /**
   * Following method is related to show popup or navigate as per provided  business logic for all sub-menus of Procedure Premium Insurance Payment and should not be removed.
   * @param sideMenuItem : when we click on one of the menu items in the side navigation bar, that menu item  is represented by sideMenuItem parameter here.
   * @param subMenu : This param hold all the information and properties for the clicked submenu item.
   * @param errorFaqModal : This is a template ref for show error faq modal, contains HTML to show in pop-up
   * @param progressModal : This is a template ref for show error faq modal, contains HTML to show in pop-up
   * @param taxCertificate : This is a template ref for Tax certificate, contains HTML to show in pop-up
   * @returns : [ModalConfig | null, string | null]
   */
  procedurePremiumInsurancePayment(sideMenuItem: string, subMenu: SideNavConfigItem, errorFaqModal: TemplateRef<any>, progressModal: TemplateRef<any>, creditCardChange: TemplateRef<any>): [ModalConfig | null, string | null] {
    if (subMenu.metadata) {
      // As per clicked submenu, respected function get called.
      switch (sideMenuItem) {
        case ProceduresInsurancePremiumPayment.ChangeTransferAccount:
          return this.setChangeTransferAccount(sideMenuItem, subMenu, errorFaqModal);
        default:
          return this.setCreditCardChangeModal(sideMenuItem, subMenu, errorFaqModal, progressModal, creditCardChange);

      }
    }
    // if no condition met or metadata value is not provided, then returning null, it means no action is required.
    return [null, null];
  }

  /**
* Following method is related to show popup or navigate as per provided  business logic for Change Transfer Account sub-menu and should not be removed.
* @param sideMenuItem : when we click on one of the menu items in the side navigation bar, that menu item  is represented by sideMenuItem parameter here.
* @param subMenu : This param hold all the information and properties for the clicked submenu item.
* @param errorFaqModal : This is a template ref for show error faq modal, contains HTML to show in pop-up
* @returns : [ModalConfig | null, string | null]
*/
  setChangeTransferAccount(sideMenuItem: string, subMenu: SideNavConfigItem, errorFaqModal: TemplateRef<any>): [ModalConfig | null, string | null] {
    let redirectUrl;
    if (subMenu.metadata) {
      if (subMenu.metadata["popupActionCode"] == ProcedurePremiumInsuranceErrorCodes.CWSBAC001
        || subMenu.metadata["popupActionCode"] == ProcedurePremiumInsuranceErrorCodes.CWSBAC002) {
        redirectUrl = this.emailEnquiryNavigation(subMenu);
        // we are returning both , config and redirection. In this case , redirection url : on pop-up we need to perform navigation on action button .
        return [this.errorFaqModalConfig(errorFaqModal), redirectUrl];
      }
      else {
        if (subMenu.metadata["callCenterUser"]) {
          redirectUrl = CwsUrlNavigator.CWS_Navigation_URL(SFDCNavigationEventUrlMapping[sideMenuItem], true, true, true, false, true);
        }
        else {
          redirectUrl = CwsUrlNavigator.CWS_Navigation_URL(SFDCNavigationEventUrlMapping[sideMenuItem], true, true);
        }
        // in case of no pop-up and only navigation , returning null.
        return [null, redirectUrl];
      }
    }
    // if no condition met, then returning null, it means no action is required.
    return [null, null];
  }

  /**
 * Following method is related to show popup or navigate as per provided  business logic for Credit Card Change sub-menu and should not be removed.
 * @param sideMenuItem : when we click on one of the menu items in the side navigation bar, that menu item  is represented by sideMenuItem parameter here.
 * @param subMenu : This param hold all the information and properties for the clicked submenu item.
 * @param errorFaqModal : This is a template ref for show error faq modal, contains HTML to show in pop-up
 * @param progressModal : This is a template ref for show error faq modal, contains HTML to show in pop-up
 * @param creditCardChange : This is a template ref for credit Card Change modal, contains HTML to show in pop-up
 * @returns : [ModalConfig | null, string | null]
 */
  setCreditCardChangeModal(sideMenuItem: string, subMenu: SideNavConfigItem, errorFaqModal: TemplateRef<any>, progressModal: TemplateRef<any>, creditCardChange: TemplateRef<any>): [ModalConfig | null, string | null] {
    //popupActionCode : As per business , this property will return values and we need to show popup or navigate to url.

    if (subMenu.metadata["popupActionCode"] == ProcedurePremiumInsuranceErrorCodes.CWSCCC006) {
      let redirectUrl;
      if (subMenu.metadata["callCenterUser"]) {
        redirectUrl = CwsUrlNavigator.CWS_Navigation_URL(SFDCNavigationEventUrlMapping[sideMenuItem], true, true, true, false, true);
      }
      else {
        redirectUrl = CwsUrlNavigator.CWS_Navigation_URL(SFDCNavigationEventUrlMapping[sideMenuItem], true, true);
      }
      const modalConfig: ModalConfig = {
        header: "app.common-modal.procedurePremiumInsurance.creditCardChange.header",
        buttons: [
          {
            text: 'app.common-modal.yes-button',
            result: "true",
            classes: 'btn btn2'
          },
          {
            text: 'app.common-modal.no-button',
            result: "false",
            classes: 'btn btn3'
          }
        ],
        customBody: creditCardChange,
        translate: true
      };
      return [modalConfig, redirectUrl];
    }
    else
      // Call common popup action , if not specfic business error code not exists.
      return this.commonPopUpActionItems(subMenu, errorFaqModal, progressModal);
  }

  /**
   * Following method is related to show popup or navigate as per provided  business logic for all sub-menus of Loans fund with withdrawals and should not be removed.
   * @param sideMenuItem : when we click on one of the menu items in the side navigation bar, that menu item  is represented by sideMenuItem parameter here.
   * @param subMenu : This param hold all the information and properties for the clicked submenu item.
   * @param errorFaqModal : This is a template ref for show error faq modal, contains HTML to show in pop-up
   * @param progressModal : This is a template ref for show error faq modal, contains HTML to show in pop-up
   * @param taxCertificate : This is a template ref for Tax certificate, contains HTML to show in pop-up
   * @returns : [ModalConfig | null, string | null]
   */
  loansFundWithdrawals(sideMenuItem: string, subMenu: SideNavConfigItem, errorFaqModal: TemplateRef<any>, progressModal: TemplateRef<any>): [ModalConfig | null, string | null] {
    if (subMenu.metadata && Object.keys(subMenu.metadata).length) {
      switch (subMenu.metadata["popupActionCode"]) {
        case LoansFundWithdrawalsErrorCodes.CWSUPL008:
          // in case of no pop-up and only navigation , returning null.
          return [null, CwsUrlNavigator.CWS_Navigation_URL(SFDCNavigationEventUrlMapping[sideMenuItem], true, true)];
        default:
          // Call common popup action , if not specfic business error code not exists.
          return this.commonPopUpActionItems(subMenu, errorFaqModal, progressModal);

      }
    }
    // if no condition met or metadata value is not provided, then returning null, it means no action is required.
    return [null, null];
  }

  /**
 * Following method is related to show popup or navigate as per provided  business logic for common for all menu and sub-menus and should not be removed.
 * @param subMenu : This param hold all the information and properties for the clicked submenu item.
 * @param errorFaqModal : This is a template ref for Show error FAQ Modal, contains HTML to show in pop-up
 * @param progressModal : This is a template ref for Progress Modal, contains HTML to show in pop-up
 * @returns : [ModalConfig | null, string | null]
 */
  commonPopUpActionItems(subMenu: SideNavConfigItem, errorFaqModal: TemplateRef<any>, progressModal: TemplateRef<any>): [ModalConfig | null, string | null] {
    if (subMenu.metadata && Object.keys(subMenu.metadata).length) {
      switch (subMenu.metadata["popupActionCode"]) {
        /* ======================================================== Show Error FAQ Modal Condition ========================================================*/
        case CancellationErrorCodes.CWSTDSB001:
        case CancellationErrorCodes.CWSRWS001:
        case CancellationErrorCodes.CWSRWS002:
        case ReissueErrorCodes.CWSREI001:
        case ReissueErrorCodes.CWSREI002:
        case ProcedurePremiumInsuranceErrorCodes.CWSCCC001:
        case LoansFundWithdrawalsErrorCodes.CWSUPL001:
        case LoansFundWithdrawalsErrorCodes.CWSUPL005:
        case LoansFundWithdrawalsErrorCodes.CWSUPL006:
          let redirectUrl = this.emailEnquiryNavigation(subMenu)
          // we are returning both , config and redirection. In this case , redirection url : on pop-up we need to perform navigation on action button .
          return [this.errorFaqModalConfig(errorFaqModal), redirectUrl];
        /* ================================================================================================================================================*/

        /* ======================================================== Enter Upgrade navigation Condition ====================================================*/
        case CancellationErrorCodes.CWSTDSB004:
        case CancellationErrorCodes.CWSRWS005:
        case ReissueErrorCodes.CWSREI005:
        case ProcedurePremiumInsuranceErrorCodes.CWSCCC004:
        case LoansFundWithdrawalsErrorCodes.CWSUPL004:
          // in case of no pop-up and only navigation , returning null.
          // SFDCOOD-35 : To enable the membership upgrade, enable the below line and remove return [null,null]
          //return [null, CwsUrlNavigator.CWS_Navigation_URL(SFDCNavigationEventUrlMapping["enterUpgradePassword"], true, true)];
          return [null,null];
        /* ================================================================================================================================================*/

        /* ======================================================== Membership Upgrade navigation Condition ===============================================*/
        case CancellationErrorCodes.CWSTDSB005:
        case CancellationErrorCodes.CWSRWS006:
        case ReissueErrorCodes.CWSREI006:
        case ProcedurePremiumInsuranceErrorCodes.CWSCCC005:
        case LoansFundWithdrawalsErrorCodes.CWSUPL007:
          // in case of no pop-up and only navigation , returning null.
          // SFDCOOD-35 : To enable the membership upgrade, enable the below line and remove return [null,null]
          //return [null, CwsUrlNavigator.CWS_Navigation_URL(SFDCNavigationEventUrlMapping["membershipUpgrade"], true, true)];
          return [null,null];
        /* ======================================================== Show Progress Model Condition ========================================================*/
        case CancellationErrorCodes.CWSTDSB002:
        case CancellationErrorCodes.CWSTDSB003:
        case CancellationErrorCodes.CWSRWS003:
        case CancellationErrorCodes.CWSRWS004:
        case ReissueErrorCodes.CWSREI003:
        case ReissueErrorCodes.CWSREI004:
        case ProcedurePremiumInsuranceErrorCodes.CWSCCC002:
        case ProcedurePremiumInsuranceErrorCodes.CWSCCC003:
        case LoansFundWithdrawalsErrorCodes.CWSUPL002:
        case LoansFundWithdrawalsErrorCodes.CWSUPL003:
          // in case of pop-up with no action, returning null for redirection url.
          // SFDCOOD-35 : To enable the membership upgrade, enable the below line and remove return [null,null]
          //return [this.progressModalConfig(progressModal), null];
          return [null,null];
        /* ================================================================================================================================================*/

        // if no condition met or metadata value is not provided, then returning null, it means no action is required.
        default:
          return [null, null];

      }
    }
    // if no condition met or metadata value is not provided, then returning null, it means no action is required.
    return [null, null];
  }

  /**
* Following method is related to navigate to email inquiry SFDC page and common for all menus and should not be removed.
* @param subMenu : This param hold all the information and properties for the clicked submenu item.
* @returns : [string]
*/
  emailEnquiryNavigation(subMenu: SideNavConfigItem): string {
    if (subMenu.metadata["callCenterUser"]) {
      return CwsUrlNavigator.CWS_Navigation_URL(SFDCNavigationEventUrlMapping["emailInquiry"], true, true, true, false, true);
    }
    else {
      return CwsUrlNavigator.CWS_Navigation_URL(SFDCNavigationEventUrlMapping["emailInquiry"], true, true);
    }
    // here we are reeturning the email inquiery SFDC redirection and appending the query param as per call center user.
  }

  /**
 * Following method is related to show popup for error faq modal and its configuration is common and should not be removed.
 * @param errorFaqModal : This is a template ref for error faq modal , contains HTML to show in pop-up
 * @returns : ModalConfig
 */
  errorFaqModalConfig(errorFaqModal: TemplateRef<any>) {
    const popUpConfig = {
      header: 'app.common-modal.change-name.header',
      buttons: [
        {
          text: 'app.common-modal.change-name.inquiry-button',
          result: "true",
          classes: 'btn btn2'
        },
        {
          text: 'app.common-modal.cancel-button',
          result: "false",
          classes: 'btn btn2'
        }
      ],
      customBody: errorFaqModal,
      translate: true
    };
    return popUpConfig;
  }

  /**
* Following method is related to show popup for progress modal and its configuration is common and should not be removed.
* @param progressModal : This is a template ref for progress modal , contains HTML to show in pop-up
* @returns : ModalConfig
*/
  progressModalConfig(progressModal: TemplateRef<any>) {
    const popUpConfig = {
      header: 'app.common-modal.cancellation.header',
      buttons: [
        {
          text: 'info-modal.button-text',
          result: "false",
          classes: 'btn btn2'
        }
      ],
      customBody: progressModal,
      translate: true
    };
    return popUpConfig;
  }
}
