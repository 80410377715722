import { Component } from '@angular/core';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent {

  openModal() {
    const element = document.getElementById('modal-information');
    element?.classList.add('open');
  }

  hideModal() {
    const element = document.getElementById('modal-information');
    element?.classList.remove('open');
    document.body.style.overflow = 'auto';
  }

}
