import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/service/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnDestroy {
  isLoading: boolean = false;
  private subscription: Subscription;

  constructor (private loaderService: LoaderService) {
    this.subscription = this.loaderService.isLoading.subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
