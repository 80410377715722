import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorDoalogConfig } from '../models/error-dialog-model';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ErrorDoalogConfig,
    public dialog: MatDialogRef<ErrorModalComponent>) {

  }

  /**
   * closes the error popup shown on the screen and return false
   */
  cancel() {
    this.dialog.close(false);
  }
}
