import { SvgString } from "@cws-ui/ngx-cws-app-layout";
export const SVG_STRINGS: SvgString = {
  listOfContractsItem: `<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 60 60">
  <path d="M30,3C15.1,3,3,15.1,3,30s12.1,27,27,27s27-12.1,27-27C57,15.1,44.9,3,30,3z M30,52C17.9,52,8,42.1,8,30 S17.9,8,30,8s22,9.8,22,22c0,0,0,0,0,0C52,42.1,42.1,52,30,52z" style="fill: #EC6453;"></path>
  <path d="M36.3,22.2l-3.6-3.5L21.6,30L33,40.9l3.5-3.6l-7.7-7.4C28.7,29.9,36.3,22.2,36.3,22.2z" style="fill: #EC6453;"></path>
        </svg>`,
  benefitProcedureItem: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 26"><path d="M1.23-17.959h-2.52v3.193H-4.424v2.49h3.135v3.164H1.23v-3.164H4.424v-2.49H1.23Zm10.078-6.035a7.494,7.494,0,0,0-2.388-1.685,7.059,7.059,0,0,0-2.944-.63H5.947A7.41,7.41,0,0,0,3-25.664a7.393,7.393,0,0,0-2.358,1.67L0-23.32l-.674-.674a7.348,7.348,0,0,0-2.373-1.67,7.1,7.1,0,0,0-2.93-.615,7.2,7.2,0,0,0-2.754.527,7.381,7.381,0,0,0-2.285,1.465h.029l-.322.293a7.749,7.749,0,0,0-1.611,2.476,7.8,7.8,0,0,0-.586,3,7.615,7.615,0,0,0,.527,2.827A8.319,8.319,0,0,0-11.543-13.3l-.029-.029L0-.117,11.572-13.33a8.26,8.26,0,0,0,1.406-2.358,7.615,7.615,0,0,0,.527-2.827,7.766,7.766,0,0,0-.586-3.018A7.792,7.792,0,0,0,11.309-23.994ZM9.668-15,0-3.926-9.668-14.971a5.559,5.559,0,0,1-.981-1.626,5.233,5.233,0,0,1-.366-1.948,5.2,5.2,0,0,1,.41-2.051,5.571,5.571,0,0,1,1.113-1.7l.205-.176a4.8,4.8,0,0,1,1.494-.967,4.628,4.628,0,0,1,1.787-.352,4.713,4.713,0,0,1,1.963.41A4.708,4.708,0,0,1-2.49-22.266L0-19.687l2.432-2.607a5.083,5.083,0,0,1,1.582-1.069,4.79,4.79,0,0,1,1.934-.4,4.79,4.79,0,0,1,1.934.4,4.738,4.738,0,0,1,1.553,1.069,5.272,5.272,0,0,1,1.143,1.714,5.377,5.377,0,0,1,.41,2.095,5.229,5.229,0,0,1-.352,1.9,5.77,5.77,0,0,1-.967,1.641Z" transform="translate(13.506 26.309)"></path>
</svg>`,
  changeContractInformationItem: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><path d="M30 3a27 27 0 1 0 27 27A27.03 27.03 0 0 0 30 3zm12.673 44.951a21.908 21.908 0 0 1-25.363-.013v-.8a12.682 12.682 0 1 1 25.363 0zM25.23 24.427a5.012 5.012 0 1 1 5.012 5.012 5.019 5.019 0 0 1-5.012-5.012zm22.084 19.1a17.614 17.614 0 0 0-9.688-12.344 10.025 10.025 0 1 0-14.918-.166 17.632 17.632 0 0 0-10.035 12.495 21.988 21.988 0 1 1 34.641.016z"></path>
</svg>`,
  accumulatedFundTransferSwitchingItem: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.8 55">
<polygon points="15.25 37.92 3 37.92 3 57 8 57 8 42.92 15.25 42.92 15.25 57 20.25 57 20.25 56.91 15.25 56.91 15.25 37.92"/>
              <polygon points="44.75 32.48 44.75 57 44.75 57 44.75 37.48 52 37.48 52 57 57 57 57 32.48 44.75 32.48"/>
              <polygon points="32.5 17.14 32.5 6.48 15.25 6.48 15.25 37.92 20.25 37.92 20.25 56.91 20.25 56.91 20.25 11.48 27.5 11.48 27.5 56.91 27.5 56.91 27.5 17.14 32.5 17.14"/>
              <rect x="15.25" y="37.92" width="5" height="18.99"/><polygon points="44.75 32.48 44.75 17.14 32.5 17.14 32.5 56.91 27.5 56.91 27.5 57 32.5 57 32.5 22.14 39.75 22.14 39.75 57 39.75 57 39.75 32.48 44.75 32.48"/>
              <rect x="39.75" y="32.48" width="5" height="24.52"/><rect x="27.5" y="17.14" width="5" height="39.77"/>
     </svg>`,
  cancellationItem: `<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 63.8 55">
     <path d="M43.307 52H15V8h28.307v11.063l5 5V3H10v54h38.307V35.651l-5 5V52z"/>
                 <path d="M48.307 28.272v.034l-5-5v-.034l-6.46-6.459-.017.016-3.518 3.519 6.999 6.999-15.845.006.002 5 15.838-.006-6.997 6.998 3.531 3.531 6.467-6.467v.008l5-5 1.572-1.572-1.572-1.573z"/>
            </svg>`,
  reissueProcedureItem: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 23"><path d="M-9.316-17.432H-.937v2.49H-9.316Zm0,4.131h5.537v2.49H-9.316Zm17.754-8-3.6,3.633v-7.207h-18.34v23.5H4.834V-7.588l8.672-8.643ZM2.314-3.867h-13.33V-22.383H2.314v7.207L-.967-11.865V-6.8H2.314Zm.7-5.449H1.523V-10.84l6.914-6.914L9.961-16.23Z" transform="translate(13.506 24.873)"></path>
          </svg>`,
  proceduresInsurancePremiumPaymentItem: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 50">
<path d="M57 13.427L46.739 3.139 28.994 20.92H3V53.5h53.931V20.8h-7.323zM32.539 24.452l14.2-14.227 3.186 3.193-14.264 14.225h-3.122zM51.927 25.8v22.7H8V25.924h19.535v6.723H37.73l6.861-6.847z"/>
<path d="M27.519 38.84h20.262v5.004H27.519z"/>
</svg>`,
  contractDocumentsItem: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 23">
          <path d="M4.895-24.828h-15.5V.355H10.609v-19.5ZM8.285-2H-8.285V-22.5H3.938l4.348,4.32ZM1.176-17.855H-1.148v7.547l-3.227-3.227-1.668,1.641L0-5.852l6.043-6.043L4.375-13.535l-3.2,3.2Z" transform="translate(10.609 24.828)"/>
         </svg>`,
  loansFundWithdrawalsItem: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 55">
  <path d="M21.059 3.023v22.771H57V3.023zM51.97 20.764H26.088V8.052H51.97z"/>
  <path d="M39.029 17.878a3.47 3.47 0 1 0-3.47-3.47 3.474 3.474 0 0 0 3.47 3.47zM54.5 35.975H36.874a6.98 6.98 0 0 0 .065-.908 7.069 7.069 0 0 0-7.061-7.061h-12.9a2.489 2.489 0 0 0-.676.093L3 31.834l1.352 4.814 12.969-3.642h12.557a2.062 2.062 0 0 1 0 4.123H18.694v5h11.184a7.017 7.017 0 0 0 3.857-1.154h14.73l-11 11H3v5h35.5a2.5 2.5 0 0 0 1.768-.732l16-16a2.5 2.5 0 0 0-1.768-4.268z"/>
  </svg>`,
  faqItem: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27"><path d="M4.717-17.959a9.051,9.051,0,0,0-.82-3.4A9.589,9.589,0,0,0,1.9-24.126a9.127,9.127,0,0,0-2.842-1.86,8.869,8.869,0,0,0-3.457-.674,8.729,8.729,0,0,0-3.545.732,9.037,9.037,0,0,0-2.915,1.948A9.124,9.124,0,0,0-12.8-21.094a9.115,9.115,0,0,0-.7,3.574v9.17h8.73a8.723,8.723,0,0,0,.85,3.4A9.075,9.075,0,0,0-1.963-2.227,9.262,9.262,0,0,0,.879-.381,8.869,8.869,0,0,0,4.336.293h9.141V-8.848a8.549,8.549,0,0,0-.7-3.457,9.262,9.262,0,0,0-1.846-2.842,9.274,9.274,0,0,0-2.783-1.992,9.051,9.051,0,0,0-3.4-.82Zm-15.7.469A.13.13,0,0,0-11-17.549a.13.13,0,0,1-.015-.059,6.342,6.342,0,0,1,.527-2.578,6.846,6.846,0,0,1,1.436-2.109A6.846,6.846,0,0,1-6.943-23.73a6.664,6.664,0,0,1,2.578-.5,6.588,6.588,0,0,1,2.549.5A6.391,6.391,0,0,1,.278-22.339,6.9,6.9,0,0,1,1.7-20.273a6.636,6.636,0,0,1,.557,2.549,10.016,10.016,0,0,0-2.373.938,9.612,9.612,0,0,0-1.992,1.523,9.612,9.612,0,0,0-1.523,1.992,9.257,9.257,0,0,0-.908,2.344v.059h-6.445ZM10.986-2.2H4.336a6.342,6.342,0,0,1-2.578-.527A6.667,6.667,0,0,1-.337-4.16,6.978,6.978,0,0,1-1.758-6.27a6.342,6.342,0,0,1-.527-2.578,6.342,6.342,0,0,1,.527-2.578A6.978,6.978,0,0,1-.337-13.535a6.579,6.579,0,0,1,2.124-1.436A6.342,6.342,0,0,1,4.365-15.5a6.342,6.342,0,0,1,2.578.527,6.846,6.846,0,0,1,2.109,1.436,6.846,6.846,0,0,1,1.436,2.109,6.664,6.664,0,0,1,.5,2.578Zm-3.926-7.91H9.17v2.49H7.061Zm-3.75,0H5.449v2.49H3.311Zm-3.75,0H1.7v2.49H-.439Z" transform="translate(13.506 26.66)"></path>
       </svg>`,
  inquiryItem: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27">
     <path d="M12.246-9.258l-.527-.557a5.974,5.974,0,0,0-1.875-1.26,5.768,5.768,0,0,0-2.314-.469,5.9,5.9,0,0,0-1.377.161,5.026,5.026,0,0,0-1.26.483l.029-.029-.176.088-7.061-7.031.088-.205a4.9,4.9,0,0,0,.439-1.2,6.064,6.064,0,0,0,.146-1.348,5.832,5.832,0,0,0-.923-3.223,5.987,5.987,0,0,0-2.388-2.168H-5.01a4.861,4.861,0,0,0-1.216-.439A6.127,6.127,0,0,0-7.559-26.6a5.778,5.778,0,0,0-3.179.908,5.918,5.918,0,0,0-2.153,2.373v.029a5.384,5.384,0,0,0-.439,1.245,6.262,6.262,0,0,0-.146,1.362,5.873,5.873,0,0,0,.469,2.329,6.033,6.033,0,0,0,1.289,1.919l15,15.059A5.52,5.52,0,0,0,5.171-.1,6.037,6.037,0,0,0,7.5.352,5.929,5.929,0,0,0,9.814-.1a5.877,5.877,0,0,0,1.9-1.274A6.033,6.033,0,0,0,13.008-3.3a5.873,5.873,0,0,0,.469-2.329,6,6,0,0,0-.322-1.963A5.522,5.522,0,0,0,12.246-9.258ZM9.99-3.135a3.588,3.588,0,0,1-1.1.747,3.262,3.262,0,0,1-1.333.278A3.333,3.333,0,0,1,6.2-2.388a3.588,3.588,0,0,1-1.1-.747L-9.99-18.223a3.355,3.355,0,0,1-.776-1.1,3.333,3.333,0,0,1-.278-1.362,3.2,3.2,0,0,1,.1-.806,3.794,3.794,0,0,1,.278-.747l-.029.029a3.5,3.5,0,0,1,1.26-1.406,3.34,3.34,0,0,1,1.846-.527,3.2,3.2,0,0,1,.806.1,3.794,3.794,0,0,1,.747.278l-.029-.029a3.572,3.572,0,0,1,1.421,1.274,3.344,3.344,0,0,1,.542,1.86,3.2,3.2,0,0,1-.1.806,3.794,3.794,0,0,1-.278.747v-.029l-.85,1.787,9.58,9.58,1.758-.908a2.753,2.753,0,0,1,.7-.264A3.507,3.507,0,0,1,7.5-9.023a3.386,3.386,0,0,1,1.348.264,3.428,3.428,0,0,1,1.084.732l.352.352a3.75,3.75,0,0,1,.513.981,3.378,3.378,0,0,1,.19,1.128,3.417,3.417,0,0,1-.264,1.333A3.385,3.385,0,0,1,9.99-3.135Z" transform="translate(13.477 26.602)"></path>
     </svg>`,
  productIncidentalServiceItem:`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67 36">
     <path d="M51.961 26.543a18.607 18.607 0 0 1-4.031 11.543 18.451 18.451 0 0 1-25.574 3.251l14.372-14.369-3.563-3.568-14.409 14.41a5.951 5.951 0 0 1-.125-.158 18.6 18.6 0 0 1-3.709-11.107 18.526 18.526 0 0 1 18.5-18.506h18.539v18.5zM33.418 3A23.565 23.565 0 0 0 9.883 26.546 23.662 23.662 0 0 0 14.6 40.671c.006.008.219.29.577.721L3.127 53.436 6.69 57l12.063-12.06.043.034a23.356 23.356 0 0 0 14.666 5.131 23.4 23.4 0 0 0 18.413-8.883A23.664 23.664 0 0 0 57 26.541V3z"></path>
      </svg>`
};
