export type FundCollection = Fund[]

export interface Fund {
    currentFundCode: number
    currentFundName: string
    selectableFundCode: number
    selectableFundName: string
    currency: Currency
    hyperlink?: string
}

export enum Currency {
    USD = "United States dollar (USD)",
    AUD = "Australian dollar (AUD)",
    USDSymbol = "(USD)",
    AUDSymbol = "(AUD)",
}
export interface DataTableSource {
    key: string
    value: string
}