import { TrackPageLoadParams, TrackButtonParams, TrackLinkParams, TrackFundSwitchParams } from './models/gasa-adobe-analytics-model';
import { TRACK_FUND_SWITCH, TRACK_LINK_TYPE, TRACK_PAGE_NAME } from './constants';

declare global {
    interface Window {
        adobeDataLayer: any;
    }
}
declare const window: Window &
    typeof globalThis & {
        adobeDataLayer: any
    }


const dataLayer = window.adobeDataLayer || [];

/**
* Function to track page view/loading via adobe analytics
* @param pageName name of the page i.e. loaded
*/
export function trackPageLoad(pageName: string) {
    if(pageName === TRACK_PAGE_NAME.policyDetailsPage)
    dataLayer.push({
        event: "pageLoad",
        web: {
            webPageDetails: {
                name: pageName,
                language: "jp",
            },
        },
        user: {
            id: "",
        },
        policy: [
            {
                type: "",
                name: "GASA用",
                number: "",
            },
        ],
    }, () => {console.log(dataLayer)})
    else
    dataLayer.push({
        event: "pageLoad",
        web: {
            webPageDetails: {
                name: pageName,
                language: "jp",
            },
        }
    }, () => {console.log(dataLayer)})
}

/**
* Function to track link click via adobe analytics
* @param type type of link whether it's internal or external
* @param params parameters to be send to tracking event
*/
export function trackLinkClick(type: string, params: TrackLinkParams) {
    let eventInfo;
    if (type == TRACK_LINK_TYPE.internal) {
        eventInfo = {
            event: "linkClick",
            eventInfo: {
                link: {
                    label: params?.label,
                },
            },
        }
    }
    else if (type == TRACK_LINK_TYPE.external) {
        eventInfo = {
            event: "exitLinkClick",
            eventInfo: {
                exitLink: {
                    label: params?.label,
                    url: params?.url,
                },
            },
        }
    }
    dataLayer.push(eventInfo, () => {console.log(dataLayer)});
}

/**
* Function to track button click via adobe analytics
* @param params parameters to be send to tracking event
*/
export function trackButtonClick(params: TrackButtonParams) {
    dataLayer.push({
        event: "buttonClick",
        eventInfo: {
            button: {
                label: params?.label,
                link: params?.link,
            },
        },
    }, () => {console.log(dataLayer)});
}

/**
* Function to track fund switching via adobe analytics 
* @param status type of switching event whether it's start, complete or cancel
*/
export function trackFundSwitch(status: string) {
    let eventInfo;
    switch (status) {
        case TRACK_FUND_SWITCH.start:
            eventInfo = {
                event: "transactionStart",
                eventInfo: {
                    transaction: {
                        name: "Fund Switch",
                    },
                },
            };
            break;
        case TRACK_FUND_SWITCH.complete:
            eventInfo = {
                event: "transactionComplete",
                eventInfo: {
                    transaction: {
                        name: "Fund Switch",
                        data: {
                            userId: "",
                        },
                    },
                },
            };
            break;
        case TRACK_FUND_SWITCH.cancel:
            eventInfo = {
                event: "transactionCancelled",
                eventInfo: {
                    transaction: {
                        name: "Fund Switch",
                        data: {
                            userId: "",
                        },
                    },
                },
            };
            break;
    }
    dataLayer.push(eventInfo, () => {console.log(dataLayer)});
}

/**
* Function to track showing popup via adobe analytics
* @param title title of the popup 
*/
export function trackPopup(title: string) {
    dataLayer.push({
        event: "popUpDisplay",
        eventInfo: {
            popup: {
                title: title,
            },
        },
    }, () => {console.log(dataLayer)});
}


export * as GasaAdobeAnalytics from './gasa-adobe-analytics';